import Splide from '@splidejs/splide'

window.Splide = Splide
globalThis.Splide = Splide

document.querySelectorAll(`[data-slider]`).forEach((element) => {
    const value = element.dataset['slider']
    const addClass = {
        arrows: 'splide__arrows test',
        arrow: 'splide__arrow',
        prev: 'splide__arrow--prev',
        next: 'splide__arrow--next',
        pagination: 'splide__pagination',
        page: 'splide__pagination__page'
    }
    switch (value) {
        case 'partners':
            // new Splide(element, {
            //     perPage: 4,
            //     classes: addClass,
            //     arrows: false,
            //     type: 'loop',
            //     autoplay: false,
            //     breakpoints: {
            //         1024: {
            //             perPage: 3
            //         },
            //         800: {
            //             perPage: 2
            //         },
            //         430: {
            //             perPage: 1
            //         }
            //     }
            // }).mount()
            break

        case 'top-team':
            var main = new Splide(element, {
                perPage: 1,
                autoplay: true,
                interval: 4000,
                lazyLoad: 'nearby',
                classes: addClass,
                arrows: false,
                type: 'loop',
                autoplay: false,
                direction: 'ltr',
                mediaQuery: 'min',
                rewind: true,
                breakpoints: {
                    1024: {
                        direction: 'ttb',
                        heightRatio: 0.9
                    }
                }
            })

            var infos = new Splide('#sliderPersonInfos', {
                perPage: 1,
                rewind: true,
                arrows: false,
                type: 'loop',
                autoplay: true,
                interval: 4000,
                drag: false,
                pagination: false,
                isNavigation: false
            })

            main.sync(infos)
            main.mount()
            infos.mount()

            break

        case 'header-main':
            // new Splide(element, {
            //     perPage: 1,
            //     classes: addClass,
            //     arrows: false,
            //     type: 'loop',
            //     autoplay: false
            // }).mount()
            break

        default:
            new Splide(element, {
                type: 'loop',
                classes: addClass
            }).mount()
            break
    }
})
