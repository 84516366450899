import SlimSelect from 'slim-select'

export default class Select {
    constructor(selector, options = {}) {
        this.selector = selector
        this.options = Object.assign(
            {
                showSearch: true, // showSearch est une valeur booléenne qui décidera d'afficher ou non la recherche.
                searchHighlight: false, // searchHighlight est une valeur booléenne qui mettra en évidence les résultats de la recherche.
                closeOnSelect: true, // closeOnSelect est une valeur booléenne qui détermine s'il faut ou non fermer la zone de contenu lors de la sélection d'une valeur.
                hideSelected: true // Le paramètre hideSelected est utilisé pour masquer l’option actuellement sélectionnée dans la liste déroulante des options.
            },
            options
        )
        this.init()
    }
    init() {
        const select = this.selector
        new SlimSelect({
            select: select,
            settings: {
                showSearch: this.options.showSearch,
                searchHighlight: this.options.searchHighlight,
                closeOnSelect: this.options.closeOnSelect,
                hideSelected: this.options.hideSelected
            }
        })
    }
}
