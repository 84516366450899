import loader from '../../sassy/component/loader/js/Loader'

window.loader = loader
globalThis.loader = loader

document.querySelectorAll('[data-loader]').forEach((element) => {
    const value = element.dataset['loader']

    switch (value) {
        default:
            new loader(element, {
                remove: true
            })
            break
    }
})
