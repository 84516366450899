type Trads = {
    key: string;
    dict: Translations
}

type Translations = {
    [key: string]: any;
}

const trads: Trads[] = [
    {
        key: 'Is required',
        dict: {
            es: 'Es obligatorio',
            fr: "Validation obligatoire",
            en: 'Is required'
        }
    },
    {
        key: 'Is not email',
        dict: {
            es: 'No es un correo electronico',
            fr: "Ce n'est pas un e-mail valide",
            en: 'Is not email'
        }
    },
    {
        key: 'Value is incorrect',
        dict: {
            es: 'El valor es incorrecto',
            fr: 'La valeur est incorrecte',
            en: 'Value is incorrect'
        }
    },
    {
        key: 'Field is required',
        dict: {
            es: 'El campo es obligatorio',
            fr: 'Le champ est requis',
            en: 'Field is required'
        }
    },
    {
        key: 'Field is too short',
        dict: {
            es: 'El campo es demasiado corto',
            fr: 'Le champ est trop court',
            en: 'Field is too short'
        }
    },
    {
        key: 'Field is too long',
        dict: {
            es: 'El campo es demasiado largo',
            fr: 'Le champ est trop long',
            en: 'Field is too long'
        }
    },
    {
        key: 'Email is required',
        dict: {
            es: 'Correo electronico es requerido',
            fr: "L'e-mail est requis",
            en: 'Email is required'
        }
    },
    {
        key: 'Email is invalid',
        dict: {
            es: 'El correo electrónico es invalido',
            fr: "L'e-mail est invalide",
            en: 'Email is invalid'
        }
    },
    {
        key: 'Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        dict: {
            es: 'El password debe contener al menos 8 caracteres, al menos una mayúscula, una minúscula, un número y un caracter especial',
            fr: "Le mot de passe doit contenir au moins 8 caractères, au moins une majuscule, une minuscule, un chiffre et un caractère special",
            en: 'Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
        }
    }
];

export default trads;
