const ctaButton = document.querySelector('.cta--button')
const ctaButtonClose = document.querySelector('.cta--button-close')
const ctaInfos = document.querySelector('.cta--infos')
document.querySelectorAll('[data-cta]').forEach((element) => {
    ctaButton.addEventListener('click', () => {
        ctaInfos.classList.add('cta--open')
        ctaButton.classList.remove('cta--open')
    })

    ctaButtonClose.addEventListener('click', () => {
        ctaInfos.classList.remove('cta--open')
        ctaButton.classList.add('cta--open')
    })
})
