import JustValidateObj from 'just-validate';
import trads from './validator-trads.js';

export default class JustValidate {

    public selector?: HTMLElement | null;
    public language?: string;
    public validator?: JustValidateObj;

    constructor(selector = null, language = 'fr') {
        this.selector = selector;
        this.language = language;
        this.init();
    }
    public init() {
        if (this.selector) {
            this.validator = new JustValidateObj(this.selector, undefined, trads);
            this.validator.setCurrentLocale(this.language ? this.language.split('-')[0] : 'fr');
        }
    }
}
