/**
 * 
 * @class Accordion
 * @description Composant qui permet d'afficher/masquer un élément en cliquant sur un autre élément
 * @param {HTMLElement} selector - Le conteneur qui contient les éléments à afficher/masquer
 * @param {Object} options - Les options du composant
 * @param {number} options.duration - La durée de l'animation en secondes
 * @param {boolean} options.oneItem - Si true, ne permettra que d'avoir un élément ouvert à la fois
 * @example
 * const accordion = new Accordion(document.querySelector('.accordion'), {
 *     duration : 1,
 *     oneItem : true
 * });
 */
export default class Accordion {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({
            duration : .5,
            oneItem : false
        }, options);
        this.init();
    }

    /**
     * Initialise le composant
     */
    init() {
        [... this.selector.children].forEach((item) => {
            const content = item.querySelector(".accordion__content");
            content.style.setProperty('--accordion-content-height', `${content.scrollHeight}px`);
            content.style.setProperty('--accordion-duration', `${this.options.duration}s`);

            item.onclick = () => {
                
                if (item.classList.contains("open")) {
                    this.closeAccordion(item);
                } else {
                    const openItems = [... this.selector.children].filter(item => item.classList.contains("open"));
                    

                    if (openItems.length > 0 && this.options.oneItem) {
                        console.log(openItems);
                        openItems.forEach(item => {
                            this.closeAccordion(item);
                        });
                    }
                    this.openAccordion(item);
                }
            };
        });
    }

    /**
     * Ouvre l'élément accordion
     * @param {HTMLElement} accordion - L'élément à ouvrir
     */
    openAccordion(accordion) {
        accordion.classList.add('open');
    };

    /**
     * Ferme l'élément accordion
     * @param {HTMLElement} accordion - L'élément à fermer
     */
    closeAccordion(accordion){
        accordion.classList.remove('open');
    };
}

