import L from 'leaflet'

export default class MapLocation {
    constructor(selector, options = {}) {
        this.selector = selector
        this.options = Object.assign(
            {
                name: 'sassy',
                pathMarker: '#',
                lat: 47.43397,
                lng: -0.495448,
                zoom: 12,
                classMarker: 'map__marker'
            },
            options
        )
        this.init()
    }

    init() {
        const mapContainer = this.selector
        const lat = this.options.lat
        const lng = this.options.lng
        const marker = this.options.pathMarker
        const nameMarker = this.options.name
        const coord = [lat, lng]
        const zoom = this.options.zoom
        const classMarker = this.options.classMarker

        document.addEventListener('DOMContentLoaded', function () {
            const map = L.map(mapContainer, {
                center: coord,
                zoom: zoom
            })
            const myIcon = L.icon({
                iconUrl: marker,
                className: classMarker
            })
            const tiles = L.tileLayer(
                'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
                {
                    maxZoom: 18,
                    minZoom: 5,
                    attribution:
                        'Map data &copy; <a href="https://www.openstreetmap.org/copyright" rel="nofollow">OpenStreetMap</a> contributors, ' +
                        '&copy; <a href="https://carto.com/attributions" rel="nofollow">CARTO</a>'
                }
            ).addTo(map)
            L.marker(coord, {
                title: nameMarker,
                alt: nameMarker,
                icon: myIcon
            }).addTo(map)
        })
    }
}

// import L from 'leaflet';

// export default class map {
//     constructor(selector, options = {}) {
//         this.selector = selector;
//         this.options = Object.assign({
//             name: 'sassy',
//             pathMarker: '#',
//             lat: 47.4347034,
//             lng: -0.4953461,
//             zoom: 12,
//         }, options);
//         this.init();
//     }

//     init() {
//         const mapContainer = this.selector;
//         const lat = this.options.lat;
//         const lng = this.options.lat;
//         const marker = this.options.pathMarker;
//         const nameMarker = this.options.name;
//         const zoom = this.options.zoom;
//         const coord = [lat, lng];

//         document.addEventListener("DOMContentLoaded", () => {

//             var map = L.map(mapContainer, {
//                 center: coord,
//                 zoom: zoom,
//             });
//             var myIcon = L.icon({
//                 iconUrl: marker,
//                 className: 'map__marker',
//             });
//             var tiles = L.tileLayer("https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png", {
//                 maxZoom: 18,
//                 minZoom: 5,
//                 attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright" rel="nofollow">OpenStreetMap</a> contributors, ' + '&copy; <a href="https://carto.com/attributions" rel="nofollow">CARTO</a>',
//             }).addTo(map);
//             L.marker(coord, {
//                 title: nameMarker,
//                 alt: nameMarker,
//                 icon: myIcon,
//             }).addTo(map)
//         })
//     }
// }
