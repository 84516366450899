import MapLocation from '../../sassy/component/map/js/Map.js'

window.MapLocation = MapLocation
globalThis.MapLocation = MapLocation

document.querySelectorAll('[data-map]').forEach((element) => {
    const value = element.dataset['map']

    switch (value) {
        default:
            new MapLocation(element, {
                name: 'Etoile-angers',
                pathMarker: '../img/logo.png',
                lat: 47.4596263,
                lng: -0.523007,
                zoom: 12
            })
            break
    }
})
