export default class BackTop {
    constructor(selector, options = {}) {
        this.selector = selector
        this.options = Object.assign(
            {
                type: 'slide-bottom',
                position: 0
            },
            options
        )
        this.init()
    }
    init() {
        const selector = this.selector
        selector.classList.add('back-top--' + this.options.type, 'hide')
        selector.classList.add('rotate')
        selector.addEventListener('click', (e) => this.handleClick(e))
        window.addEventListener('scroll', (e) => this.handleScroll(e))
    }

    handleClick() {
        const position = this.options.position
        window.scrollTo({
            top: position
        })
    }

    handleScroll(e) {
        const selector = this.selector

        if (
            document.body.scrollTop > 200 ||
            document.documentElement.scrollTop > 200
        ) {
            selector.classList.remove('hide')
            selector.classList.remove('rotate')
        } else {
            selector.classList.add('rotate')
            selector.classList.add('hide')
        }
    }
}
