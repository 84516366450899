export const fieldName = [
    {
        rule: 'required',
        errorMessage: 'Field is required',
    },
    {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Field is too short',
    },
    {
        rule: 'maxLength',
        value: 40,
        errorMessage: 'Field is too long',
    },
]

export const fieldEmail = [
    {
        rule: 'required',
        errorMessage: 'Field is required',
    },
    {
        rule: 'email',
        errorMessage: 'Is not email',
    }
]

export const fieldPassword = [
    {
        rule: 'required',
        errorMessage: 'Field is required',
    },
    {
        rule: 'strongPassword',
        errorMessage: 'Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
    }
]
