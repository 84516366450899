import Select from '../../sassy/component/select/js/Select.js'

document.querySelectorAll('[data-select]').forEach((element) => {
    const value = element.dataset['select']

    switch (value) {
        default:
            new Select(element, {
                showSearch: false
            })
            break
    }
})
