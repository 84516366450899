import '../../sassy/component/accordion/js/init'
import '../../sassy/component/back-top/js/init'
import '../../sassy/component/dropdown/js/init'
import '../../sassy/component/form/js/init'
import './cta'
import './instafeed'
import './loader'
import './map'
import './select'
import './slider'

// console.log(config)
// console.log(components)
if (document.querySelector('.navbars__button--close')) {
    document
        .querySelector('.navbars__button--close')
        .addEventListener('click', () => {
            document
                .querySelector('.navbars__nav-wrapper')
                .classList.remove('navbars__nav--open')
        })
    document
        .querySelector('.navbars__button--open')
        .addEventListener('click', () => {
            document
                .querySelector('.navbars__nav-wrapper')
                .classList.add('navbars__nav--open')
        })
}

if (document.querySelector('.navbars')) {
    const navbars = document.querySelector('.navbars__container').offsetHeight
    const main = document.querySelector('main')
    main.style.marginTop = '-' + navbars + 'px'

    if (!document.querySelector('.header--main')) {
        window.addEventListener('scroll', () => {
            const element = document.querySelector('.navbars')
            if (window.scrollY > navbars) {
                element.style.backgroundColor = 'var(--secondary-500)'
            } else {
                element.style.backgroundColor = 'transparent'
            }
        })
    } else {
        const element = document.querySelector('.navbars')
        element.style.backgroundColor = 'var(--secondary-500)'
    }
}
