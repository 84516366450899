import Dropdown from "./Dropdown.js";

document.querySelectorAll("[data-dropdown]").forEach((element) => {
    const value = element.dataset["dropdown"];

    switch (value) {
        default:
            new Dropdown(element);
            break;
    }
});
