import Accordion from './Accordion'

window.Accordion = Accordion
globalThis.Accordion = Accordion

document.querySelectorAll('[data-accordion]').forEach((element) => {
    const value = element.dataset['accordion']

    switch (value) {
        case 'solo':
            new Accordion(element, {
                oneItem: true
            })
            break

        default:
            new Accordion(element)
            break
    }
})
